import { useState, useEffect } from 'react';
import { getPublicViewData } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';
import { getCookie } from 'cookies-next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const matomoTracking = (matomoUrl) => {
    var _mtm = (window._mtm = window._mtm || []);
    const _paq = (window._paq = window._paq || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    _paq.push([
        'setDomains',
        [
            '*.${domain}',
            '*.minasidor.jamtkraft.se',
            '*.nya-minasidor.jamtkraft.se',
        ],
    ]);
    _paq.push(['enableCrossDomainLinking']);
    var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = matomoUrl;
    s.parentNode.insertBefore(g, s);
};

export const getStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common'])),
        },
    };
};

function DynamicNotFoundPage(args) {
    // 404 does not support getServerSideProps, must fetch client side data
    // https://github.com/vercel/next.js/blob/master/errors/404-get-initial-props.md
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const { json: pageData } = await getPublicViewData('404', {
                selectedSite: getCookie('selectedSite'),
            });
            setData(pageData);
            const { matomoUrl } = pageData.componentProps.siteSetting;

            if (matomoUrl) {
                setTimeout(() => matomoTracking(matomoUrl), 100);
            }
        }
        fetchData();
    }, []);

    if (!data) {
        return null;
    }

    return <NotFoundPage {...data} />;
}

export default DynamicNotFoundPage;

function NotFoundPage({ componentName, componentProps }) {
    const Component = LazyContainers[componentName];
    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return <Component {...componentProps} />;
}

/*
// For static routing
export async function getStaticProps({ params, preview, previewData }) {
  const pageData = await getViewData('404');
  return { props: pageData }
}
*/
